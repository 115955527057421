import {waitALittle} from '@wix/panda-js-utils'
import {EditorSDK} from '@wix/platform-editor-sdk'
import {ProgressBarConfig} from '../constants'

export interface ProgressBar {
  open: (config: ProgressBarConfig) => void
  close: () => Promise<void>
}

export const SilentProgressBar = (): ProgressBar => ({
  open: () => {},
  close: () => Promise.resolve(),
})

export const SdkProgressBar = (editorSdk: EditorSDK, t: Function, token: string): ProgressBar => {
  let progressBarPromise: Promise<void>

  return {
    open: ({title, step1, step2, step3, timeBetweenSteps}: ProgressBarConfig) => {
      if (!progressBarPromise) {
        progressBarPromise = new Promise(async resolve => {
          try {
            await editorSdk.editor.openProgressBar(token, {
              title: t(title),
              totalSteps: 3,
              currentStep: 1,
              stepTitle: t(step1),
            })
            await waitALittle(timeBetweenSteps)
            await editorSdk.editor.updateProgressBar(token, {
              currentStep: 2,
              stepTitle: t(step2),
            })
            await waitALittle(timeBetweenSteps)
            await editorSdk.editor.updateProgressBar(token, {
              currentStep: 3,
              stepTitle: t(step3),
            })
            await waitALittle(800)
          } catch (e) {
            console.warn(e)
          }
          resolve()
        })
      }
    },
    close: async () => {
      if (progressBarPromise) {
        try {
          await progressBarPromise
          editorSdk.editor.closeProgressBar(token, {})
        } catch (e) {
          console.warn(e)
        }
        progressBarPromise = null
      }
    },
  }
}
