export type ProgressBarConfig = {
  title: string
  step1: string
  step2: string
  step3: string
  timeBetweenSteps: number
}

export const BASE_INSTALL_CONFIG: ProgressBarConfig = {
  title: 'membersInstallProgressBarTitle',
  step1: 'membersInstallProgressBarFirstStep',
  step2: 'membersInstallProgressBarSecondStep',
  step3: 'membersInstallProgressBarThirdStep',
  timeBetweenSteps: 4500,
}

export const PROMO_INSTALL_CONFIG: ProgressBarConfig = {
  title: 'membersPromoInstallProgressBarTitle',
  step1: 'membersInstallProgressBarFirstStep',
  step2: 'membersInstallProgressBarSecondStep',
  step3: 'membersInstallProgressBarThirdStep',
  timeBetweenSteps: 4500,
}

export const PROMO_UPDATE_CONFIG: ProgressBarConfig = {
  title: 'membersPromoUpdateProgressBarTitle',
  step1: 'membersPromoUpdateProgressBarFirstStep',
  step2: 'membersInstallProgressBarSecondStep',
  step3: 'membersInstallProgressBarThirdStep',
  timeBetweenSteps: 3000,
}
